const config = {
  server: {
    /* Prod
    api: "https://api.yongo.me",
    document: "https://dl.yongo.me",
    live: {
      ice: "stun:stun1.l.google.com:19302",
      websocket_url: "wss://media.yongo.io:5443/YongoLive/websocket",
    },
    storage: {
      bucket: "https://yongo.us-lax-1.linodeobjects.com/",
      cache: "https://yongo.us-lax-1.linodeobjects.com/",
    },
    timeout: 72e5, // 2 hours = 120*60*1000 = 7200000 = 72e5 ms
    video: "https://vl.yongo.me",
    */

    // Sandbox
    api: "https://sandboxapi.yongo.live",
    document: "https://sandboxdl.yongo.live",
    live: {
      ice: "stun:stun1.l.google.com:19302",
      websocket_url: "wss://media.yongo.io:5443/sandbox/websocket",
    },
    storage: {
      bucket: "https://sandbox-s3.ap-south-1.linodeobjects.com/",
      cache: "https://sandbox-s3.ap-south-1.linodeobjects.com/",
    },
    timeout: 72e5, // 2 hours = 120*60*1000 = 7200000 = 72e5 ms
    video: "https://sandboxvl.yongo.live",
    //

    /* Dev
    api: "http://localhost:4000",
    document: "http://localhost:4500",
    storage: {
      bucket: "https://test-s3.ap-south-1.linodeobjects.com/",
      cache: "https://test-s3.ap-south-1.linodeobjects.com/",
    },
    live: {
      ice: "stun:stun1.l.google.com:19302",
      websocket_url: "wss://media.yongo.io:5443/sandbox/websocket",
    },
    timeout: 12e5, // 1 hour = 60*60*1000 = 3600000 = 36e5 ms
    video: "http://localhost:4400",
    */
  },

  app_deeplink: "yongo.edduus://",
  app_download: {
    android: "https://play.google.com/store/apps/details?id=com.yongovc",
    ios: "https://apps.apple.com/us/app/yongo/id6477491535",
  },
  file: {
    imageTypes: ["image/png", "image/gif", "image/jpeg"],
    imageCodes: ["89-50-4e", "47-49-46", "ff-d8-ff"],
    documentTypes: [
      "application/pdf",
      "application/msword",
      "application/vnd.ms-powerpoint",
      " application/vnd.ms-excel",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".xls",
      ".xlsx",
    ],
    documentCodes: [
      "25-50-44",
      "0-6e-1e",
      "9-8-10",
      "d-44-4f",
      "f-0-e8",
      "50-4b-3",
      "a0-46-1d",
      "cf-11-e0",
      "d0-cf-11",
      "db-a5-2d",
      "ec-a5-c1",
      "fd-ff-ff",
    ],
  },
  expiration_ms: {
    recent_live: 9e5, // 15 minutes = 15*60*1000 = 900,000 = 9e5 ms
  },
  levels: [
    "role_member",
    "role_instructor",
    "role_content",
    "role_director",
    "role_owner",
    "admin",
  ],
  max_block_details: 4,
  max_attachment: 20000000, //20MB
  pexels: {
    key: "LlL8aJpfr7ZmiO8IZMdexf6eRGqU7x0N8BL6cihi7p6YTKIkJLlZlf5s",
  },
  pricing: [{ plan: "free" }, { plan: "community" }, { plan: "enterprize" }],
  unwrapped_routes: [
    "account_deletion",
    "admin",
    "fwd",
    "hub",
    "invitation",
    "ref",
    "sso",
    "vid",
    "forgot",
    "login",
    "pwd",
    "signup",
    "timedout",
    "privacy",
    "support",
    "terms_conditions",
    "yongo",
  ],
  string: {
    article: 8000,
    paragraph: 2000,
    bio: 800,
    search: 500,
    email: 320,
    brief: 120,
    title: 80,
    short: 10,
  },
  thumb_grid_keys: [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
    "PageUp",
    "PageDown",
    "CTRL+Home",
    "CTRL+End",
  ],
  video: {
    resolution: 1920, //1080p 1920x1080
    max_duration: 1200, // 1200 = 20*60 = 20 minutes
    max_filesize: 750000000, //bytes
    min_duration: 12,
  },
};

export default config;
