import { React, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";

import { Preamble, Preview, Image, Success } from "./blockSteps";
import axiosCall from "../../../lib/axios";
import { sessionExpired } from "../../../lib/common";
import config from "../../../config";

const NewBlock = ({ language, signout, pageRefs, pageFunctions }) => {
  //initialize
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [pageHeading, setPageHeading] = useState(language.labels.bites.add_one);

  console.log(state);

  //props
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [preamble, setPreamble] = useState(null);
  const [referredBy, setReferredBy] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [ignored, setIgnored] = useState([]);
  const [preEmpt, setPreEmpt] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [equivalent, setEquivalent] = useState(false);
  const [vague, setVague] = useState(false);
  const [extraSections, setExtraSections] = useState([]);
  const [gotPathways, setGotPathways] = useState(false);
  const [communityPathways, setCommunityPathways] = useState([]);
  const [bitePathways, setBitePathways] = useState([]);
  const [successLabel, setSuccessLabel] = useState(
    language.labels.bites.success.create
  );
  const [dlp, setDlp] = useState("");
  const [bid, setBid] = useState(null);

  //steps
  const [currentStep, setCurrentStep] = useState(-1);
  const [nextStep, setNextStep] = useState([]);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);

      //check state
      if (state?.preamble) setPreamble(state.preamble);
      if (state?.referredBy) setReferredBy(state.referredBy);
      if (state?.similar) {
        setDuplicates(state.similar);
        setPreEmpt(true);
        setCurrentStep(1);
      } else {
        setCurrentStep(0);
      }
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    //set heading
    if (preEmpt) {
      if (referredBy === "video") {
        setPageHeading(language.labels.videos.add);
      } else {
        setPageHeading(language.labels.knowledge.add);
      }
    }
  }, [preEmpt]);

  /////////// CREATE BLOCK  ////////////////
  const success = async (bid, tab, pathways = false) => {
    if (referredBy) {
      navigate("/bite", {
        state: { bid, referredBy },
      });
    } else {
      setBid(bid);
      if (pathways) setSuccessLabel(language.labels.bites.success.pathways);

      document.getElementById(tab).style.animationName = "slideout";
      next({ step: 3, direction: 1 });
    }
  };

  const createBlock = async (image) => {
    // try and submit data
    const data = {
      title,
      description,
      thumb: image,
      extras: extraSections,
    };

    let result = await axiosCall("block/create", data);
    if (result.success) {
      if (result.status === 200) {
        success(result.data._id, "animateImage");
      } else {
        setDlp(result.data.dlp);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      createBlock(image);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// STEPS /////////////////
  const next = async (step) => {
    // check we haven't timed out first
    let timedout = await sessionExpired();

    if (timedout) {
      signout();
    } else {
      setNextStep(step);
      setTimeout(() => {
        setCurrentStep(step.step);
        setNextStep([]);
      }, 300);
    }
  };

  const allSteps = [
    <Preamble
      language={language}
      animation={nextStep}
      next={next}
      signout={signout}
      props={{
        preamble,
        referredBy,
        title,
        setTitle,
        description,
        setDescription,
        extraSections,
        setExtraSections,
        setVague,
        setEquivalent,
        setDuplicates,
        ignored,
        setBitePathways,
      }}
    />,
    <Preview
      language={language}
      animation={nextStep}
      duplicates={duplicates}
      community={community}
      next={next}
      navigate={navigate}
      signout={signout}
      props={{
        vague,
        equivalent,
        preEmpt,
        setPreEmpt,
        setIgnored,
        gotPathways,
        setGotPathways,
        communityPathways,
        setCommunityPathways,
        bitePathways,
        referredBy,
        setPageHeading,
        success,
      }}
    />,
    <Image
      next={next}
      animation={nextStep}
      language={language}
      createBlock={createBlock}
      dlp={dlp}
      setDlp={setDlp}
      props={{ title, description, extraSections, cid: community.id }}
    />,
    <Success
      bid={bid}
      title={title}
      successLabel={successLabel}
      referredBy={referredBy}
      language={language}
      animation={nextStep}
      navigate={navigate}
    />,
  ];

  if (config.levels.indexOf(community.role) < 2) {
    return <></>;
  } else {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {pageHeading}
                </h1>
                <div className="handle2">{community.title}</div>
              </div>
            </div>
          </div>

          <div>
            {allSteps[currentStep]}
            {nextStep && allSteps[nextStep.step]}
          </div>
        </main>
      </div>
    );
  }
};

export default NewBlock;
