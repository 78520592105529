import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import axiosCall from "../../lib/axios";
import { naturalDate } from "../../lib/date";
import config from "../../config";

const Flagged = ({ language, signout, pageRefs, pageFunctions }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const [currentTab, setCurrentTab] = useState("video");
  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState("active");

  const allReports = useRef([]);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(role));
      getFlagged(id);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  const getFlagged = async (id = null) => {
    let data = { tab: currentTab };
    if (id) {
      data.cid = id;
    } else {
      data.cid = community.id;
    }
    let result = await axiosCall("community/getFlagged", data);

    if (result.success) {
      // setReports(result.data);
      allReports.current = result.data;
      filter_flag("active");
    } else if (result.refresh) {
      //token has been refreshed, try again
      getFlagged(id);
    } else {
      //refresh token expired or unknown error
      // signout();
    }
  };

  const gotoContent = (id) => {
    if (currentTab === "video") {
      let report = reports.find((report) => report.video === id);
      navigate("/video", {
        state: { params: { _id: id }, flagged: true, report: report },
      });
    } else {
      let report = reports.find((report) => report.id === id);
      if (report.answer) {
        navigate("/answerflag", {
          state: {
            thread: {
              _id: id,
            },
            flagged: true,
            report: report,
          },
        });
      } else {
        navigate("/thread", {
          state: {
            thread: {
              _id: id,
              knowledge_type: report.answer ? "answer" : "general",
            },
            flagged: true,
            report: report,
          },
        });
      }
    }
  };

  //////////// TAB INTERACTIONS ///////////
  function switchTab(e) {
    const tp = ["video", "knowledge"];
    var n = tp.indexOf(e.target.id);
    if (n > -1) {
      if (e.key === "ArrowRight") n++;
      if (e.key === "ArrowLeft") n--;
      if (n > -1 && n < tp.length) {
        setCurrentTab(tp[n]);
        document.getElementById(tp[n]).focus();
      }
    }
  }

  const filter_flag = (filter) => {
    let filtered_reports = allReports.current.filter(
      (report) => report.status === filter
    );
    setReports(filtered_reports);
    setFilter(filter);
  };

  useEffect(() => {
    if (community.id) getFlagged();
  }, [currentTab]);

  if (level > 2 && !community?.draft) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {community.uname}
                </h1>
                <div className="handle2">{community.title}</div>
              </div>
            </div>
          </div>

          <div className="page-section">
            {/* subtab menu */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1.5em",
                margin: "0.5em 0 1em 0",
              }}
              role="tablist"
            >
              <div
                role="tab"
                id="video"
                className={`subtab ${currentTab !== "video" && "off"}`}
                onClick={() => setCurrentTab("video")}
                aria-selected={currentTab === "video" ? "true" : "false"}
                tabIndex={currentTab === "video" ? "0" : "-1"}
                onKeyUpCapture={(e) => switchTab(e)}
              >
                <span>{language.labels.navigation.videos}</span>
                <div></div>
              </div>

              <div
                role="tab"
                id="knowledge"
                className={`subtab ${currentTab !== "knowledge" && "off"}`}
                onClick={() => setCurrentTab("knowledge")}
                aria-selected={currentTab === "knowledge" ? "true" : "false"}
                tabIndex={currentTab === "knowledge" ? "0" : "-1"}
                onKeyUpCapture={(e) => switchTab(e)}
              >
                <span>{language.labels.navigation.knowledge}</span>
                <div></div>
              </div>
            </div>
            <div
              className="filter"
              style={{
                display: "flex",
                gap: "0.5em",
                margin: "0.5em 0",
              }}
            >
              <button
                className={filter === "active" ? "on" : "off"}
                value="week"
                onClick={() => {
                  filter_flag("active");
                }}
              >
                {language.labels.flags.status.active}
              </button>
              <button
                className={filter === "pending" ? "on" : "off"}
                value="semester"
                onClick={() => {
                  filter_flag("pending");
                }}
              >
                {language.labels.flags.status.pending}
              </button>
              <button
                className={filter === "rejected" ? "on" : "off"}
                value="never"
                onClick={() => {
                  filter_flag("rejected");
                }}
              >
                {language.labels.flags.status.rejected}
              </button>
              <button
                className={filter === "accepted" ? "on" : "off"}
                value="month"
                onClick={() => {
                  filter_flag("accepted");
                }}
              >
                {language.labels.flags.status.accepted}
              </button>
            </div>
          </div>

          <div
            className="page-section"
            style={{ maxWidth: "66rem", borderBottom: "1em" }}
          >
            {reports.length > 0 ? (
              reports.map((report, index) => (
                <div
                  role="figure"
                  key={index}
                  className="card-long hover"
                  onClick={() => {
                    gotoContent(report.id ? report.id : report.video);
                  }}
                  tabIndex={0}
                >
                  <div className="block-info">
                    <div role="link" className="block-title" tabIndex={0}>
                      {language.labels.flags.id.replace(
                        /{id}/g,
                        report.id ? report.id : report.video
                      )}
                    </div>
                    {report.id && (
                      <div
                        style={{
                          fontSize: "12px",
                          margin: "0.2em 0",
                          fontWeight: "500",
                        }}
                      >
                        {report.answer ? "Answer" : "Article"}
                      </div>
                    )}

                    <div style={{ fontSize: "0.8em", margin: "0.2em 0 0.5em" }}>
                      {naturalDate(
                        report.created,
                        language.locale,
                        language.labels.date
                      )}
                    </div>

                    <div>
                      <div>{report.category}</div>
                    </div>
                    <div className="quote-text" style={{ marginTop: "0.5em" }}>
                      <div>{report.description}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5em",
                        marginTop: "0.5em",
                      }}
                      className="font-contrast"
                    >
                      <div>{language.labels.flags.status[report.status]}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                {language.labels.communities.flag[`${currentTab}_none`]}
              </div>
            )}
          </div>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Flagged;
