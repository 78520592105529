import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import { ContentList } from "../widgets";
import Modal from "../modal/Modal";

const Knowledge = ({
  language,
  pageHeight,
  pageWidth,
  signout,
  pageRefs,
  pageFunctions,
}) => {
  //////////// INITIALIZATION ///////////
  const auth = useSelector((state) => state.authentication.value);
  const [thumb_height, set_thumb_height] = useState(190);
  //fix per_page to largish value as resizing problematic
  const [per_page] = useState(7);
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [displayModal, setDisplayModal] = useState(false);

  const account_ref = useRef();

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    if (pageWidth < 500) set_thumb_height(310);
  }, [pageWidth]);

  //////////// RENDER GUI ///////////
  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />
      <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
        <ContentList
          language={language}
          api={"community/threads"}
          community={community}
          content={"knowledge"}
          page_title={language.labels.navigation.knowledge}
          new_link={"/newknowledge"}
          thumb_height={thumb_height}
          per_page={per_page}
          pageHeight={pageHeight}
          account={auth.account_type}
          setDisplayModal={setDisplayModal}
          signout={signout}
        />
      </main>
      <Modal
        ref={account_ref}
        className="modal-dynamic"
        title={language.labels.account.upgrade}
        show={displayModal}
        language={language}
      >
        <section
          className="focus-modest"
          id="modal_body"
          aria-label={language.labels.account.upgrade}
          tabIndex={0}
          style={{ padding: "1em 0" }}
        >
          {language.labels.account.free_tier}
        </section>
        <div style={{ display: "flex", padding: "0.5em 0" }}>
          <button className="button-on" onClick={() => setDisplayModal(false)}>
            {language.labels.app.ok}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Knowledge;
