import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import axiosCall from "../../lib/axios";
import navGrid from "../../lib/thumbGrid";
import { naturalDate } from "../../lib/date";
import { InviteForm, InviteEditor } from "../widgets";
import config from "../../config";

const Invites = ({ language, signout, pageRefs, pageFunctions }) => {
  ////////////////// INITIALIZE //////////////////
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);
  const [invitations, setInvitations] = useState([]);
  const [inviteForm, setInviteForm] = useState(false);
  const [invited, setInvited] = useState([]);
  const [existing, setExisting] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState(-1);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(_community.role));
      getInvites(id);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  const getInvites = async (id = null) => {
    let data = {};
    if (id) {
      data.community = id;
    } else {
      data.community = community.id;
    }
    let result = await axiosCall("community/invitations", data);
    if (result.success) {
      setInvitations(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getInvites(id);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  useEffect(() => {
    if (existing?.length > 0 || invited?.length > 0) {
      setShowResult(true);
      setTimeout(() => {
        setShowResult(false);
      }, 30000);
    }
  }, [existing, invited]);

  //////////// EDIT ///////////
  const handleEdit = (idx) => {
    setEdit(edit === idx ? -1 : idx);
  };

  const revoke = async (iid) => {
    setShowResult(false);
    const data = { iid: iid, cid: community.id };
    let result = await axiosCall("invite/revoke", data);

    if (result.success) {
      setEdit(-1);
      getInvites();
    } else if (result.refresh) {
      //token has been refreshed, try again
      revoke(iid);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// KEYBOARD INTERACTION //////////////////
  const keyAction = (e, i) => {
    //navigated grid
    if (e.target.tagName.toUpperCase() === "DIV") {
      //detect ctrl+key
      let key;
      if (e.ctrlKey || e.metaKey) {
        key = "CTRL+" + e.key;
      } else {
        key = e.key;
      }

      if (e.key === "Enter" || e.key === " ") {
        handleEdit(i);
      } else {
        const _key = config.thumb_grid_keys.indexOf(key);
        if (_key >= 0) {
          let container = document.getElementById("thumb_wall");
          let contents = container.querySelectorAll("div[tabindex]");
          navGrid(container, contents, key);
        }
      }
    }
  };

  ////////////////// RENDER GUI //////////////////
  const pending_invites = invitations.map((invitation, i) => (
    <div
      className="thumb-group hover"
      key={i}
      role="figure"
      aria-label={invitation.invitee_email}
      onKeyDown={(e) => keyAction(e, i)}
      onClick={(e) => {
        handleEdit(i);
      }}
      tabIndex="0"
    >
      <div className="thumb-group-content">
        <div
          className="glyphs font-yongo"
          style={{ fontWeight: 500, fontSize: "2em" }}
        >
          E
        </div>

        <div className="handle" style={{ margin: "0.75em 0" }}>
          {invitation.invitee_email}
        </div>
        <div>{language.labels.roles[invitation.role]}</div>
        <div className="handle2" style={{ marginTop: "0.25em" }}>
          {naturalDate(
            invitation.created,
            language.locale,
            language.labels.date
          )}
        </div>
      </div>
    </div>
  ));

  if (level > 2 && !community?.draft) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {language.labels.navigation.community_invites}
                </h1>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>

          {edit < 0 ? (
            <>
              <div className="page-section">
                <div
                  dangerouslySetInnerHTML={{
                    __html: language.labels.invitation.blurb,
                  }}
                />
                <button
                  className="button"
                  style={{ margin: "1em" }}
                  onClick={() => {
                    setEdit(-1);
                    setInviteForm(true);
                  }}
                >
                  {language.labels.invitation.invite}
                </button>
              </div>
              <InviteForm
                language={language}
                community={community}
                getInvites={getInvites}
                signout={signout}
                params={{
                  inviteForm,
                  setInviteForm,
                  setInvited,
                  setExisting,
                  setShowResult,
                  setError,
                }}
              />

              {error && (
                <div className="page-section" style={{ paddingTop: "0px" }}>
                  <div className="errtext">{error}</div>
                </div>
              )}

              {showResult && (
                <div className="page-notification">
                  {/* close button */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      padding: "0.5em 1.5em",
                    }}
                  >
                    <button
                      className="glyphs"
                      onClick={() => setShowResult(false)}
                    >
                      x
                    </button>
                  </div>

                  {invited.length === 1 && (
                    <div>
                      {language.labels.invitation.success.replace(
                        /{invitee}/,
                        invited[0]
                      )}
                    </div>
                  )}
                  {invited.length > 1 && (
                    <div>
                      {language.labels.invitation.successes.replace(
                        /{invitees}/,
                        invited.join(", ")
                      )}
                    </div>
                  )}
                  {existing.length === 1 && (
                    <div>
                      {language.labels.invitation.already.replace(
                        /{invitee}/,
                        existing[0]
                      )}
                    </div>
                  )}
                  {existing.length > 1 && (
                    <div>
                      {language.labels.invitation.alreadys.replace(
                        /{invitees}/,
                        existing.join(", ")
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* pending invitations */}
              <div className="page-section">
                <div className="quote-text ">
                  {invitations.length > 0
                    ? language.labels.invitation.pending.replace(
                        /{n}/g,
                        invitations.length
                      )
                    : language.labels.invitation.none}
                </div>
                {invitations.length > 0 && edit < 0 && (
                  <div id="thumb_wall" className="thumb-group-container">
                    {pending_invites}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="page-section" style={{ marginTop: "0.5em" }}>
              <InviteEditor
                invitation={invitations[edit]}
                language={language}
                getInvites={getInvites}
                signout={signout}
                params={{ revoke, setEdit }}
              />
            </div>
          )}
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Invites;
