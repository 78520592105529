import { React, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { persistor } from "./store";
import { logout } from "./features/authentication";
import Loader from "react-spinners/PuffLoader";
import Cookies from "universal-cookie";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";

import "./css/Main.scss";
import "./css/Common.scss";
import "./css/Media.css";
import "./css/fonts.css";

import config from "./config";
import translator from "./lib/translator";
import { logoff, gohome } from "./lib/logoff";

import { Login, Forgot, Password, Signup, TimedOut } from "./UI/auth";
import {
  Account,
  Admin,
  AnswerFlag,
  Blocks,
  BlockDetails,
  CommunityHub,
  Context,
  Deeplink,
  Directory,
  Flagged,
  Invites,
  Knowledge,
  LivePlay,
  LivePublish,
  LiveStream,
  LiveStreams,
  Managers,
  NewBlock,
  NewBlocks,
  NewCommunity,
  NewKnowledge,
  NewPathway,
  NewVideo,
  Pathway,
  Pathways,
  PathwayStream,
  Thread,
  Topics,
  Video,
  VideoHub,
  Welcome,
} from "./UI/hub";
import {
  AccountDelete,
  Hub,
  Invitation,
  PageNotFound,
  Redirect,
  Sso,
  Terms,
  Vid,
} from "./UI/utility";
import { Policy, Support, Yongo } from "./UI/pub";

//fix language direction
//only add supported rtl languages to the array - all languages are ['ar','az','dv','ff','he','ku','fa','ur']
const rtl = [];
const base_lang = navigator.language.substring(0, 2).toLowerCase();
let cssFile = "ltr.css";
if (rtl.indexOf(base_lang) > -1) {
  cssFile = "rtl.css";
}
import(`./css/${cssFile}`);

function App() {
  ////////// INITIALIZE /////////
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(translator.getTranslator());
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [pageHeight, setPageHeight] = useState(window.innerHeight);
  const [os, setOs] = useState("desktop");

  //page refs
  const main_focus = useRef();
  const _main_ = useRef();
  const skip_link = useRef();
  const sidebar_ref = useRef();

  useEffect(() => {
    console.log("APP JS USE EFFECT");
    console.log(cookies.get("Yongo"));
    console.log(localStorage.getItem("last_updated"));
    if (cookies.get("Yongo") && localStorage.getItem("last_updated")) {
      let last_updated = localStorage.getItem("last_updated");
      console.log(
        "CHECK USER LAST UPDATED ",
        Date.now() - last_updated > config.server.timeout
      );
      if (Date.now() - last_updated > config.server.timeout) {
        console.log("timed out");
        signout();
      } else {
        initializeGui();
      }
    } else {
      console.log("new session");
      signout(false);
      initializeGui();
    }
  }, []);

  useEffect(() => {
    //localize SEO
    document.documentElement.lang = language.lang;
    document.title = language.labels.brand.web;
    const metas = document.getElementsByTagName("meta");
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") === "description") {
        metas[i].setAttribute("content", language.labels.brand.description);
      }
      if (metas[i].getAttribute("property") === "og:title") {
        metas[i].setAttribute("content", language.labels.brand.web);
      }
      if (metas[i].getAttribute("property") === "og:description") {
        metas[i].setAttribute("content", language.labels.brand.description);
      }
    }
  }, [language]);

  ////////// OPERATING SYSTEM /////////
  const getOs = () => {
    var userAgent = navigator.userAgent;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "desktop";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/ipad|iphone|ipod/i.test(userAgent)) {
      return "ios";
    }
    return "desktop";
  };

  ////////// SET PAGE /////////
  const initializeGui = () => {
    cookies.set("Yongo", "ok");
    setLoading(false);

    //detect operating system
    setOs(getOs);

    //detect window size fit side menu
    function handleResize() {
      setPageWidth(window.innerWidth);
      setPageHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);

    //navigator language change
    function handleLanguage() {
      setLanguage(translator.getTranslator());
    }
    window.addEventListener("languagechange", handleLanguage);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("languagechange", handleLanguage);
    };
  };

  //get the policy docs
  const checkPolicies = async () => {
    const configurationObject = {
      url: `${config.server.api + "/policies/hub"}`,
      method: "POST",
      data: { lang: language.lang },
      withCredentials: true,
      credentials: "include",
    };
    try {
      let policies = await axios(configurationObject);
      if (policies.status === 200) {
        return policies.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  ////////// FUNCTIONS /////////
  const signout = (reload = true) => {
    let client_id = localStorage.getItem("client_id") || "";
    persistor.purge();
    dispatch(logout());
    sessionStorage.removeItem("invitation");
    localStorage.clear();
    cookies.remove("Yongo");
    console.log("SIGNOUT CALLED CLIENT ID", client_id);
    if (client_id) {
      logoff(client_id, reload);
    } else {
      gohome(reload);
    }
  };

  const skipLink = (show = true) => {
    if (skip_link.current) {
      if (show) {
        skip_link.current.classList.add("visible");
      } else {
        skip_link.current.classList.remove("visible");
      }
    }
  };

  const toggleMenu = () => {
    if (pageWidth < 900 && sidebar_ref.current.clientWidth === 0) {
      sidebar_ref.current.style.width = "100%";
      sidebar_ref.current.firstChild.style.width = "100%";
    } else {
      sidebar_ref.current.style.width = "0px";
      sidebar_ref.current.firstChild.style.width = "0px";
    }
  };

  const resetMenu = () => {
    if (pageWidth < 900) {
      sidebar_ref.current.style.width = "0px";
      sidebar_ref.current.firstChild.style.width = "0px";
    } else {
      sidebar_ref.current.style.width = "220px";
      sidebar_ref.current.firstChild.style.width = "220px";
    }
  };

  ////////// ROUTES /////////
  return (
    <>
      {loading ? (
        <div
          className="loader"
          style={{
            top: `${pageHeight / 2 - 46 + "px"}`,
            left: `${pageWidth / 2 - 46 + "px"}`,
            textAlign: "center",
          }}
        >
          <Loader
            color={"blue"}
            loading={true}
            aria-label={language.labels.aria.loading}
            size={80}
          />
          <div
            style={{
              opacity: "0.7",
              position: "absolute",
              top: "38px",
              width: "92px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {language.labels.aria.loading}
          </div>
        </div>
      ) : (
        <>
          <div id="loader" style={{ zIndex: 10 }}>
            <Loader
              color={"blue"}
              loading={true}
              cssOverride={{
                marginTop: `${pageHeight / 2 - 46 + "px"}`,
                marginLeft: `${pageWidth / 2 - 46 + "px"}`,
              }}
              aria-label={language.labels.aria.loading}
              size={80}
            />
            <div
              style={{
                position: "absolute",
                opacity: "0.7",
                top: `${pageHeight / 2 - 8 + "px"}`,
                left: `${pageWidth / 2 - 46 + "px"}`,
                width: "92px",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {language.labels.aria.loading}
            </div>
          </div>
          <Router>
            <Routes>
              <Route path="*" element={<PageNotFound language={language} />} />

              <Route path="/" element={<Yongo language={language} os={os} />} />

              {/* Public Routes */}
              <Route
                path="/privacy"
                element={
                  <Policy checkPolicies={checkPolicies} language={language} />
                }
              />
              <Route
                path="/support"
                element={
                  <Support checkPolicies={checkPolicies} language={language} />
                }
              />
              <Route
                path="/terms_conditions"
                element={
                  <Policy
                    checkPolicies={checkPolicies}
                    policy="TC"
                    language={language}
                  />
                }
              />
              <Route
                path="/yongo"
                element={<Yongo language={language} os={os} />}
              />

              {/* Auth Routes */}
              <Route path="/forgot" element={<Forgot language={language} />} />
              <Route
                path="/login"
                element={
                  <Login language={language} checkPolicies={checkPolicies} />
                }
              />
              <Route path="/pwd" element={<Password language={language} />} />
              <Route
                path="/signup"
                element={
                  <Signup checkPolicies={checkPolicies} language={language} />
                }
              />
              <Route
                path="/timedout"
                element={<TimedOut language={language} />}
              />

              {/* Utility Routes */}
              <Route
                path="/account_deletion"
                element={
                  <AccountDelete
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/fwd"
                element={<Redirect language={language} signout={signout} />}
              />
              <Route path="/hub" element={<Hub signout={signout} />} />
              <Route
                path="/invitation"
                element={
                  <Invitation
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route path="/ref" element={<Redirect />} />
              <Route path="/sso" element={<Sso language={language} />} />
              <Route
                path="/terms"
                element={
                  <Terms
                    language={language}
                    signout={signout}
                    checkPolicies={checkPolicies}
                  />
                }
              />
              <Route
                path="/vid"
                element={
                  <Vid
                    language={language}
                    signout={signout}
                    pageHeight={pageHeight}
                  />
                }
              />

              {/* Hub Routes */}
              <Route
                path="/account"
                element={
                  <Account
                    language={language}
                    signout={signout}
                    checkPolicies={checkPolicies}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/admin"
                element={
                  <Admin
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/answerFlag"
                element={
                  <AnswerFlag
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/bite"
                element={
                  <BlockDetails
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/bites"
                element={
                  <Blocks
                    language={language}
                    signout={signout}
                    pageHeight={pageHeight}
                    pageWidth={pageWidth}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/communityhub"
                element={
                  <CommunityHub
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/directory"
                element={
                  <Directory
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/flagged"
                element={
                  <Flagged
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/gotoapp"
                element={
                  <Deeplink
                    language={language}
                    os={os}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/invites"
                element={
                  <Invites
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/knowledge"
                element={
                  <Knowledge
                    language={language}
                    pageHeight={pageHeight}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/livestream"
                element={
                  <LiveStream
                    language={language}
                    signout={signout}
                    pageHeight={pageHeight}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/livestreams"
                element={
                  <LiveStreams
                    language={language}
                    signout={signout}
                    pageHeight={pageHeight}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/mycommunity"
                element={
                  <Context
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newbite"
                element={
                  <NewBlock
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newbites"
                element={
                  <NewBlocks
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newpathway"
                element={
                  <NewPathway
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newcommunity"
                element={
                  <NewCommunity
                    checkPolicies={checkPolicies}
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newknowledge"
                element={
                  <NewKnowledge
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/newvideo"
                element={
                  <NewVideo
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/pathway"
                element={
                  <Pathway
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/pathways"
                element={
                  <Pathways
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/pathwaystream"
                element={
                  <PathwayStream
                    language={language}
                    signout={signout}
                    os={os}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/personnel"
                element={
                  <Managers
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/playstream"
                element={<LivePlay language={language} signout={signout} />}
              />
              <Route
                path="/pubstream"
                element={<LivePublish language={language} signout={signout} />}
              />
              <Route
                path="/theme"
                element={
                  <Topics
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/thread"
                element={
                  <Thread
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/video"
                element={
                  <Video
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/videos"
                element={
                  <VideoHub
                    language={language}
                    signout={signout}
                    pageHeight={pageHeight}
                    pageWidth={pageWidth}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
              <Route
                path="/welcome"
                element={
                  <Welcome
                    language={language}
                    signout={signout}
                    pageRefs={{ skip_link, sidebar_ref, main_focus, _main_ }}
                    pageFunctions={{ skipLink, toggleMenu, resetMenu }}
                  />
                }
              />
            </Routes>
          </Router>
        </>
      )}
    </>
  );
}

export default App;
