import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CsvDownloader from "react-csv-downloader";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import axiosCall from "../../lib/axios";

const Admin = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ///////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  const communityHeader = [
    { id: "_id", displayName: "Community Number" },
    { id: "uname", displayName: "Community ID" },
    { id: "title", displayName: "Community Name" },
    { id: "type", displayName: "Type" },
    { id: "remittance", displayName: "Remittance" },
    { id: "active", displayName: "Active" },
    { id: "created", displayName: "Created (Y-M-D)" },
    { id: "verified", displayName: "Verified" },
    { id: "exclusive", displayName: "Exclusive" },
    { id: "stream_license", displayName: "Enterprise Accounts Granted" },
  ];

  //////////// DATA FUNCTIONS ///////////
  const communityReport = async (attempt = 1) => {
    try {
      let result = await axiosCall("admin/communities");
      if (result.success) {
        return result.data;
      } else if (result.refresh) {
        throw new Error();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } catch (err) {
      if (attempt < 2) {
        return communityReport(attempt + 1);
      } else {
        //unknown error
        signout();
      }
    }
  };

  if (auth.admin) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div
            className="page-section mobile-justify"
            style={{ display: "flex" }}
          >
            <h1 className="heading">
              {language.labels.app.admin.toUpperCase()}
            </h1>
          </div>

          <div className="page-section">
            <div className="text-label" style={{ fontSize: "1.1em" }}>
              Downloads
            </div>
            <div style={{ marginTop: "0.5em" }}>
              <span>&nbsp; - &nbsp;</span>
              <CsvDownloader
                style={{ display: "inline" }}
                className="link"
                filename="community list"
                columns={communityHeader}
                datas={communityReport}
              >
                Community List
              </CsvDownloader>
            </div>
          </div>

          <div className="page-section" style={{ marginTop: "1em" }}>
            <div className="text-label" style={{ fontSize: "1.1em" }}>
              Community Lookup
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};
export default Admin;
