import React, { useState, useEffect, useRef } from "react";

import config from "../../config";
import axiosCall from "../../lib/axios";
import validator from "../../lib/validation";

const StaffEditor = ({ member, language, signout, params }) => {
  //////////// INITIALIZATION ///////////
  const email_ref = useRef();
  const role_ref = useRef();

  const [email, setEmail] = useState();
  const [memberMail, setMemberMail] = useState();
  const [errEmail, setErrEmail] = useState(null);
  const [errAccount, setErrAccount] = useState(null);
  const [errRemove, setErrRemove] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setEmail(member.email);
    setMemberMail(member.email);
  }, []);

  //////////// FUNCTIONS ///////////
  const setBlur = () => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (document.activeElement.getAttribute("name") !== "email_field") {
        setEmail(memberMail);
      }
    }, 100);
  };

  //change email
  const checkEmail = () => {
    setErrEmail(null);
    setErrAccount(null);
    setErrRemove(null);
    if (email_ref.current.value) {
      let newEmail = email_ref.current.value.toLowerCase().trim();
      if (newEmail) {
        if (validator.emailValidator(newEmail)) {
          submitEmail(newEmail);
        } else {
          setErrEmail(language.labels.error.email_invalid);
        }
      } else {
        setEmail(memberMail);
      }
    } else {
      setEmail(memberMail);
    }
  };

  const submitEmail = async (address) => {
    let result = await axiosCall("staff/rename", {
      address,
      user: member.uid,
    });
    if (result.success) {
      if (result.status === 200) {
        //success
        setMemberMail(address);
        setRefresh(true);
      } else {
        //taken
        setErrEmail(language.labels.authentication.email_in_use);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      submitEmail(address);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const changeRole = (e) => {
    setErrEmail(null);
    setErrAccount(null);
    setErrRemove(null);
    setRefresh(true);
    params.changeRole(member.role, e.target.value, member.uid);
  };

  const removeRole = () => {
    setErrEmail(null);
    if (role_ref.current.value === "role_owner") {
      setErrRemove(language.labels.error.owner);
    } else {
      params.removeRole(member.uid);
    }
  };

  const changeEnterprizeAccount = async (e) => {
    setErrEmail(null);
    setErrAccount(null);
    setErrRemove(null);
    let result = await axiosCall("staff/changeaccount", {
      enterprize: e.target.checked,
      user: member.uid,
    });
    if (result.success) {
      if (result.status === 201) {
        //cap exceeded
        e.target.checked = false;
        setErrAccount(language.labels.account.enterprize_exceeded);
      }
      params.resetCap(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeEnterprizeAccount(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const closeEditor = async () => {
    if (refresh) params.getManagers();
    params.setEdit(-1);
  };

  //////////// RENDER GUI ///////////
  return (
    <div
      className="mobile-center thumb-group-content"
      style={{ position: "relative", maxWidth: "20rem", margin: "10px 0" }}
    >
      {/* close button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          top: 0,
          width: "100%",
          padding: "0.75em",
        }}
      >
        <button
          className="glyphs"
          style={{ fontSize: "1.2em" }}
          onClick={closeEditor}
        >
          x
        </button>
      </div>

      {/* display avatar */}
      <img
        className="profile-image"
        src={config.server.storage.bucket + member.avatar}
        alt={member.username}
        style={{ marginTop: "0.5em" }}
      />

      {/* display username */}
      <div
        className="name"
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {member.uid === params.uid ? (
          <>{member.username + " (" + language.labels.app.me + ")"}</>
        ) : (
          <>{member.username}</>
        )}
      </div>

      {/* email editor */}
      <label style={{ marginTop: "1em" }}>
        {language.labels.authentication.email_contact}
      </label>
      <div className="natural-edit" style={{ display: "flex", width: "19rem" }}>
        <input
          ref={email_ref}
          style={{ flex: 1 }}
          name="email_field"
          value={email}
          aria-placeholder={language.labels.authentication.email_contact}
          placeholder={language.labels.authentication.email_contact}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setBlur()}
          onKeyDown={(e) => e.key === "Enter" && checkEmail()}
          maxLength={config.string.title}
        />
        <div
          style={{
            flexBasis: "3.5rem",
            display: "flex",
            justifyContent: "space-around",
            margin: "-0.25rem",
          }}
        >
          <div
            role="button"
            className="glyphs accept"
            name="email_field"
            title={language.labels.app.apply}
            aria-label={language.labels.app.apply}
            onBlur={() => setBlur()}
            onClick={() => checkEmail()}
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") && checkEmail()
            }
            tabIndex={0}
          >
            *
          </div>
          <div
            role="button"
            className="glyphs reject"
            name="email_field"
            style={{ fontSize: "0.7rem" }}
            title={language.labels.app.discard}
            aria-label={language.labels.app.discard}
            onBlur={() => setBlur()}
            onClick={() => setEmail(memberMail)}
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") && setEmail(memberMail)
            }
            tabIndex={0}
          >
            x
          </div>
        </div>
      </div>
      {errEmail && (
        <div className="errtext" role="alert">
          {errEmail}
        </div>
      )}

      {/* role */}
      <div style={{ marginTop: "1em" }}>
        <label style={{ padding: "0 0.5em" }}>
          {language.labels.members.download.role}
        </label>
        <select
          ref={role_ref}
          className="large"
          style={{ textAlign: "center", padding: "0.1em" }}
          aria-label={language.labels.roles.role_choose}
          onChangeCapture={(e) => {
            if (member.role !== "role_owner") changeRole(e);
          }}
          disabled={member.role === "role_owner"}
        >
          <option
            value="role_instructor"
            selected={member.role === "role_instructor"}
          >
            {language.labels.roles.role_instructor}
          </option>
          <option
            value="role_content"
            selected={member.role === "role_content"}
          >
            {language.labels.roles.role_content}
          </option>
          <option
            value="role_director"
            selected={member.role === "role_director"}
          >
            {language.labels.roles.role_director}
          </option>
          {config.levels.indexOf(member.role) > 2 && (
            <option value="role_owner" selected={member.role === "role_owner"}>
              {language.labels.roles.role_owner}
            </option>
          )}
        </select>
      </div>

      {/* enterprize account */}
      {params.stream_license > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
            gap: "0.5em",
          }}
        >
          <label htmlFor="enterprize_account">
            {language.labels.account.enterprize}
          </label>
          <input
            id="enterprize_account"
            type={"checkbox"}
            onChange={(e) => changeEnterprizeAccount(e)}
            defaultChecked={member.enterprize}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
        </div>
      )}
      {errAccount && (
        <div className="errtext" role="alert">
          {errAccount}
        </div>
      )}

      {/* remove */}
      {params.level >= config.levels.indexOf(member.role) &&
        config.levels.indexOf(member.role) < 4 && (
          <button
            className="button"
            style={{ margin: "2em 0 0.5em" }}
            onClick={() => {
              removeRole();
            }}
          >
            {language.labels.app.remove}
          </button>
        )}
      {errRemove && (
        <div className="errtext" role="alert">
          {errRemove}
        </div>
      )}
    </div>
  );
};
export default StaffEditor;
