import React, { useState, useEffect, useRef } from "react";

import config from "../../../config";

import axiosCall from "../../../lib/axios";
import { duration } from "../../../lib/date";

const StreamViewers = ({ language, pageHeight, signout, params }) => {
  //////////// INITIALIZATION ///////////
  const pageRef = useRef();
  const bottomRef = useRef();
  const loadingRef = useRef();
  const hasMoreRef = useRef();

  const [viewerList, setViewerList] = useState([]);

  //fix per_page to largish value as resizing problematic
  const per_page = 20;

  useEffect(() => {
    //ini refs
    pageRef.current = 0;
    loadingRef.current = false;
    hasMoreRef.current = true;

    //initial load
    getViewers();

    //listen to scroll
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //////////// SCROLLING ///////////
  const handleScroll = () => {
    //check if within 200px of top
    if (bottomRef.current) {
      if (bottomRef.current.getBoundingClientRect().top < pageHeight + 200) {
        getViewers(pageRef.current);
      }
    }
  };

  //////////// DATA ///////////
  const getViewers = async (page = 0, force = false) => {
    //only call api if not loading and has more unless forced via refresh token
    if ((loadingRef.current || !hasMoreRef.current) && !force) return;
    loadingRef.current = true;

    //call api
    let result = await axiosCall("live/viewers", {
      page,
      limit: per_page,
      streamId: params.streamId,
    });
    loadingRef.current = false;

    if (result.success) {
      console.log(result.data);
      if (page === 0) {
        setViewerList(result.data);
      } else {
        setViewerList((prevData) => [...prevData, ...result.data]);
      }

      //fix refs
      if (result.data.length === 0 || result.data.length < per_page)
        hasMoreRef.current = false;
      pageRef.current = pageRef.current + 1;
    } else if (result.refresh) {
      //token has been refreshed, try again
      getViewers(page, force);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////
  const mapped = viewerList.map((viewer, index) => {
    return (
      <div role="figure" className="card-long" key={index} tabIndex={0}>
        <div>
          <div
            style={{
              backgroundImage: `url(${config.server.storage.bucket}${viewer.avatar})`,
              flexShrink: "0",
              borderRadius: "50%",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>
        </div>

        <div className="block-info">
          <div
            className="clamped one"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {viewer.expert
              ? viewer.name + " (" + language.labels.app.expert + ")"
              : viewer.name}
          </div>
          {viewer.fullname && (
            <div
              className="clamped one handle2"
              style={{ marginTop: "0.25em" }}
            >
              {viewer.fullname}
            </div>
          )}

          <div style={{ fontWeight: 300, marginTop: "0.5em" }}>
            {language.labels.live.viewers.time.replace(
              /{time}/g,
              duration(viewer.duration / 1000)
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div style={{ maxWidth: "66rem", paddingBottom: "1em" }}>
      {/* back button */}
      <div
        style={{ display: "flex", alignItems: "center", gap: "2px" }}
        onClick={() => params.setShowViewers(false)}
      >
        <button
          className="glyphs link-button"
          aria-label={language.labels.live.back}
          onClick={() => params.setShowViewers(false)}
          style={{ fontSize: "0.75em" }}
        >
          &lt;
        </button>
        <div className="link" aria-hidden={true}>
          {language.labels.live.back}
        </div>
      </div>

      <div style={{ marginTop: "1em", fontWeight: 500 }}>
        {!params.total
          ? language.labels.live.viewers.none
          : `${
              params.total > 1
                ? language.labels.live.viewers.multiple.replace(
                    /{n}/g,
                    params.total
                  )
                : language.labels.live.viewers.one
            }`}
      </div>

      <div style={{ maxWidth: "66rem", paddingBottom: "1em" }}>{mapped}</div>
      <div
        ref={bottomRef}
        style={{
          height: "50px",
          marginBottom: "1em",
          width: "100%",
          maxWidth: "60rem",
          textAlign: "center",
        }}
      >
        {hasMoreRef.current && (
          <div
            role="status"
            aria-label={language.labels.aria.loading}
            className="batch-loading glyphs"
          >
            Z
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamViewers;
