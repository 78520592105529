import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";
import config from "../../../config";
import TabList from "../../widgets/live/TabList";

const LiveStreams = ({
  language,
  pageHeight,
  signout,
  pageRefs,
  pageFunctions,
}) => {
  //////////// INITIALIZATION ///////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const readOnly = auth.account_type === "free";

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(role));
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  //////////// KEY NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e, container) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// ACTION ///////////
  const goToStream = (streamId) => {
    navigate("/livestream", { state: { streamId } });
  };

  //////////// RENDER GUI ///////////
  if (community.draft) {
    return <></>;
  } else {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {language.labels.navigation.live_streams}
                </h1>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>

          <div className="page-section" style={{ maxWidth: "66rem" }}>
            {readOnly && (
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "1.2em",
                  paddingTop: "0.5em",
                }}
              >
                {language.labels.account.upgrade}
              </div>
            )}
            {readOnly && (
              <div style={{ marginTop: "0.5em" }} className="quote-text">
                {language.labels.live.subscription_only}
              </div>
            )}

            {/* new stream */}
            {community.role !== "role_member" && !readOnly && (
              <button
                style={{
                  alignSelf: "flex-start",
                  marginTop: "0.5em",
                  fontWeight: "500",
                }}
                className="button"
                onClick={() => {
                  navigate("/pathwaystream");
                }}
              >
                {language.labels.live.start_new}
              </button>
            )}
          </div>

          {!readOnly && community.id && (
            <div className="page-section">
              <TabList
                language={language}
                signout={signout}
                pageHeight={pageHeight}
                params={{ community, goToStream, navResults }}
              />
            </div>
          )}
        </main>
      </div>
    );
  }
};

export default LiveStreams;
