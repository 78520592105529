import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { naturalDate } from "../../../lib/date";
import validator from "../../../lib/validation";
import { KnowledgeCreator, KnowledgeViewer } from "../../widgets";

const BlockKnowledge = ({
  level,
  community,
  language,
  bid,
  blockKnowledge,
  knowledgeState,
  setKnowledgeState,
  getKnowledge,
  setRefreshKnowledge,
  account,
  setDisplayModal,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  const [currentThread, setCurrentThread] = useState({});
  const [removedAnswer, setRemovedAnswer] = useState(null);

  //////////// NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("threadResults");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// THREADS ///////////

  const onSuccess = () => {
    getKnowledge(true);
  };

  const onClose = (refresh = false) => {
    if (refresh) {
      getKnowledge(true);
    } else {
      setKnowledgeState("list");
    }
  };

  const onCancel = () => {
    setKnowledgeState("list");
  };

  const displayThread = (thread) => {
    if (account === "free" || level < 0) {
      setDisplayModal(true);
    } else {
      setCurrentThread(thread);
      setKnowledgeState("view");
    }
  };

  const refreshKnowledge = (answer) => {
    if (answer) {
      setRemovedAnswer(answer);
    }
    setRefreshKnowledge(true);
  };

  //////////// RENDER GUI ///////////

  const BlockKnowledge = blockKnowledge.map((thread, idx) => (
    <div
      role="figure"
      key={idx}
      className="card-long hover"
      onClick={() => displayThread(thread._id)}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
      style={{ width: "100%" }}
    >
      <div
        role="link"
        onKeyDown={(e) => e.key === "Enter" && displayThread(thread._id)}
        tabIndex={0}
        style={{ display: "flex", gap: "1em", width: "100%" }}
      >
        <div
          className="glyphs font-contrast-hard"
          style={{ fontSize: "2.4em", marginTop: "-5px" }}
        >
          {language.labels.knowledge.icon[thread.knowledge_type]}
        </div>
        <div>
          <div
            className="clamped one"
            style={{
              fontWeight: "500",
              fontSize: "1.1em",
              marginBottom: "0.25em",
            }}
          >
            {thread.title}
          </div>
          <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {naturalDate(thread.created, language.locale, language.labels.date)}
          </div>
          {thread?.community === thread.bite_community ||
          thread.poster_endorsed ? (
            <div className="handle" style={{ fontSize: "11px" }}>
              {language.labels.app.expert}
            </div>
          ) : (
            <div
              style={{ fontSize: "11px" }}
              dangerouslySetInnerHTML={{
                __html: language.labels.app.posted_by.replace(
                  /{user}/g,
                  thread.poster_name
                ),
              }}
            />
          )}

          <div
            style={{ fontSize: ".9em", marginTop: "0.5em" }}
            className={`quote-text${
              thread.status === "available" && " clamped two"
            }`}
          >
            {thread.status === "available"
              ? thread.post
              : thread.status === "pending"
              ? language.labels.knowledge.pending
              : language.labels.app.images_processing}
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "0.5em",
              alignItems: "center",
              opacity: "0.8",
            }}
          >
            <div
              role="img"
              className="glyphs"
              style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
              aria-label={language.labels.aria.reads}
              data-tooltip-id="read-tooltip"
              data-tooltip-content={language.labels.aria.reads}
            >
              u
            </div>
            <div style={{ fontSize: "0.8em" }}>
              {validator.bigNumber(thread.views)}
            </div>
            <div style={{ flexBasis: "2em" }}></div>
            <div
              role="img"
              className="glyphs"
              style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
              aria-label={language.labels.aria.likes}
              data-tooltip-id="like-tooltip"
              data-tooltip-content={language.labels.aria.likes}
            >
              O
            </div>
            <div style={{ fontSize: "0.8em" }}>
              {validator.bigNumber(thread.likes)}
            </div>
            {thread.answers > 0 && (
              <>
                <div style={{ flexBasis: "2em" }}></div>
                <div
                  role="img"
                  className="glyphs"
                  style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                  aria-label={language.labels.aria.answers}
                  data-tooltip-id="answer-tooltip"
                  data-tooltip-content={language.labels.aria.answers}
                >
                  *
                </div>
                <div style={{ fontSize: "0.8em" }}>
                  {validator.bigNumber(thread.answers)}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div
      role="tabpanel"
      style={{ paddingBottom: "2em" }}
      aria-label={language.labels.navigation.knowledge}
      tabIndex={0}
    >
      {knowledgeState === "list" && (
        <div style={{ width: "100%", maxWidth: "60rem" }}>
          {/* add knowledge */}
          {level > 0 && (
            <div
              style={{ display: "flex", gap: "0.5em", alignItems: "center" }}
            >
              <button
                className="button-glyph"
                onClick={() => {
                  setKnowledgeState("new");
                }}
              >
                +
              </button>
              <div>
                <span
                  className="link"
                  onClick={() => {
                    setKnowledgeState("new");
                  }}
                >
                  {language.labels.knowledge.add}
                </span>
              </div>
            </div>
          )}

          <div style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}>
            <div>
              {blockKnowledge.length < 1
                ? language.labels.knowledge.none_bite
                : `${
                    blockKnowledge.length > 1
                      ? language.labels.knowledge.multiple.replace(
                          /{n}/g,
                          blockKnowledge.length
                        )
                      : language.labels.knowledge.one
                  }`}
            </div>
            {/* refresh */}
            {level > 0 && (
              <button
                aria-label={language.labels.aria.refresh_knowledge}
                className="glyphs success"
                onClick={() => getKnowledge(true)}
              >
                r
              </button>
            )}
          </div>
          <div id="threadResults">{BlockKnowledge}</div>
        </div>
      )}

      {knowledgeState === "new" && (
        <KnowledgeCreator
          bid={bid}
          language={language}
          signout={signout}
          onCancel={onCancel}
          onSuccess={onSuccess}
          cancelLabel={language.labels.app.cancel}
        ></KnowledgeCreator>
      )}

      {knowledgeState === "view" && (
        <KnowledgeViewer
          style={{ maxWidth: "1600px", paddingBottom: "2em" }}
          community={community}
          language={language}
          currentThread={currentThread}
          onClose={onClose}
          onChange={refreshKnowledge}
          onDelete={onSuccess}
          removedAnswer={removedAnswer}
          bite={true}
          signout={signout}
        ></KnowledgeViewer>
      )}

      {/* tooltips */}
      <Tooltip id="read-tooltip" />
      <Tooltip id="like-tooltip" />
      <Tooltip id="answer-tooltip" />
    </div>
  );
};

export default BlockKnowledge;
